import { MdOutlineClass } from 'react-icons/md'
import Dashboard from '../pages/Dashboard'
import Users from '../pages/Users'
import Reservations from '../pages/Reservations'
import Exchanges from '../pages/Exchanges'
import Enterprises from '../pages/Enterprises'
import GeneralSettings from '../pages/GeneralSettings'
// import UserEditPage from '../pages/UserEditPage'
import TestePage from '../pages/Teste'
import { BiBuildingHouse, BiCalendar, BiCog, BiHome, BiRepeat, BiUserPlus } from 'react-icons/bi'
import { AiOutlineSwap } from 'react-icons/ai'
import UsersCadastroPage from '../pages/Users/UsersCadastro'
import EnterprisesCadastroPage from '../pages/Enterprises/EnterprisesCadastro'
import ExchangesForm from '../pages/Exchanges/ExchangesCadastro'
import ReservationForm from '../pages/Reservations/ReservationsEdit'
import UsersCPT from '../pages/UsersCpt'
import Balance from '../pages/Balance'
import Ownership from '../pages/Ownership'
import Quotes from '../pages/Quotes'
import Products from '../pages/Products'
import ProductsEdit from '../pages/Products/ProductsCadastro'
import HabitationalUnits from '../pages/SmartWeeks'
import SmartWeekCadastroPage from '../pages/SmartWeeks/SmartWeekCadastro'
import GW from '../pages/GW'
// import UsersCreatePage from '../pages/UserCreatePage'

export const PrivateRoutes = [
  {
    name: 'Dashboard',
    path: '/',
    component: Dashboard,
    componentCreate: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BiHome,
  },
  {
    name: 'Usuários',
    path: '/users',
    component: Users,
    componentCreate: UsersCadastroPage,
    componentEdit: UsersCadastroPage,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BiUserPlus,
  },
  {
    name: 'Usuários CPT',
    path: '/users-cpt',
    component: UsersCPT,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'none',
    icon: BiUserPlus,
  },
  {
    name: 'Extrato de Viajex',
    path: '/balance/:itemId',
    component: Balance,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'none',
    icon: BiUserPlus,
  },
  {
    name: 'Depositos semanais',
    path: '/quotes/:itemId',
    component: Quotes,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'none',
    icon: BiUserPlus,
  },
  {
    name: 'Extrato de propriedade',
    path: '/ownership/:itemId',
    component: Ownership,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'none',
    icon: BiUserPlus,
  },
  {
    name: 'Reservas',
    path: '/reservations',
    component: Reservations,
    // componentCreate: ReservationsCreate,
    componentEdit: ReservationForm,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BiCalendar,
  },
  {
    name: 'Intercâmbios',
    path: '/exchanges',
    component: Exchanges,
    componentCreate: ExchangesForm,
    componentEdit: ExchangesForm,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: AiOutlineSwap,
  },
  {
    name: 'Empresas',
    path: '/enterprises',
    component: Enterprises,
    componentCreate: EnterprisesCadastroPage,
    componentEdit: EnterprisesCadastroPage,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BiBuildingHouse,
  },
  {
    name: 'Produtos',
    path: '/products',
    component: Products,
    componentCreate: null,
    componentEdit: ProductsEdit,
    componentShow: false,
    showSidebar: false,
    localeSidebar: 'top',
    icon: BiBuildingHouse,
  },
  {
    name: 'SmartWeeks',
    path: '/smartweeks',
    component: HabitationalUnits,
    componentCreate: null,
    componentEdit: SmartWeekCadastroPage,
    componentShow: false,
    showSidebar: false,
    localeSidebar: 'top',
    icon: BiBuildingHouse,
  },
  {
    name: 'Configurações gerais',
    path: '/general-settings',
    component: GeneralSettings,
    // componentCreate: GeneralSettingsCreatePage,
    // componentEdit: GeneralSettingsEditPage,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BiCog,
  },
  {
    name: 'ShareWeek',
    path: '/share-week',
    component: GW,
    // componentCreate: GeneralSettingsCreatePage,
    // componentEdit: GeneralSettingsEditPage,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BiCog,
  },
  {
    name: 'Teste',
    path: '/teste',
    component: TestePage,
    componentShow: false,
    showSidebar: false,
    localeSidebar: 'top',
    icon: MdOutlineClass,
  },
]
