import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Input, Select, Text, useToast } from '@chakra-ui/react';
import ResumeCard from '../../components/ResumeCard';
import ReactTable from '../../components/ReactTable';
import { format } from 'date-fns';
import { ReturnButton } from '../../components/Buttons/ReturnButton';
import { BiEdit, BiFilter, BiUser, BiWallet } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';
import IUsers from '../../interfaces/IUsers';
import { api } from '../../services/api';
import { VscTrash, VscEdit, VscChecklist } from 'react-icons/vsc';
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton';
import ConfirmModal from '../../components/ConfirmModal';
import ConfirmModalExport from '../../components/ConfirmModal/ConfirmModalExport';
import { useFetch } from '../../hooks/useFetch';

const Balance = () => {
  const baseUrl = '/v1/cashclubs/balance/';
  const { requestApi, error } = useFetch('');
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [usuario, setUsuario] = useState<any>({});
  const [cashClubs, setCashClubs] = useState<any>({});
  const [pageSize, setPageSize] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState<IUsers[]>([]);
  const [total, setTotal] = useState(0);
  const [isOpen, onOpen] = useState(true);
  const toast = useToast();
  const { itemId } = useParams();

  useEffect(() => {
    getUsers();
  }, [pageNumber, perPage]);

  const getUsers = async () => {
    try {
      const getBalance = await api.get(`v1/cashclubs/balance/${itemId}?pageNumber=${pageNumber}&perPage=${perPage}`);
      const getUser = await api.get(`v1/users/${itemId}`);
      const getCashClubs = await api.get(`v1/cashclubs/pointsbalance/${itemId}`);
      if (getBalance.status === 200) {
        console.log('Aqui:::', getBalance.data);
        setData(getBalance.data.cashClubs);
        setUsuario(getUser.data);
        setCashClubs(getCashClubs.data);
        setPageSize(getBalance.data.paginateCount);
        setTotal(getBalance.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Detalhes',
        columns: [
          {
            Header: 'Id da transação',
            accessor: 'cc_id',
          },
          {
            Header: 'Data de lançamento',
            accessor: 'deposited_at',
            Cell: ({ row }: any) => {
              return <Text>{format(new Date(row.original.deposited_at), 'dd/MM/yy HH:mm')}</Text>;
            },
          },
          {
            Header: 'Data de vencimento',
            accessor: 'expire_at',
            Cell: ({ row }: any) => {
              return <Text>{format(new Date(row.original.expire_at), 'dd/MM/yy HH:mm')}</Text>;
            },
          },
          {
            Header: 'Tipo',
            accessor: 'transaction_type',
            Cell: ({ row }: any) => {
              return row.original.transaction_type === 'D' ? (
                <Text color="green.500">Deposito</Text>
              ) : (
                <Text color="red.500">Saque</Text>
              );
            },
          },
          {
            Header: 'Abrangência',
            accessor: 'abrangency',
            Cell: ({ row }: any) => {
              return row.original.abrangency === 'N' ? <Text>Nacional</Text> : <Text>Internacional</Text>;
            },
          },
          {
            Header: 'CashClub',
            accessor: 'cashclubs',
            Cell: ({ row }: any) => {
              return row.original.transaction_type === 'D' ? (
                <Text color="green.500">+ {row.original.cashclubs}</Text>
              ) : (
                <Text color="red.500">- {row.original.cashclubs}</Text>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Flex>
          <ReturnButton onClick={() => navigate(`/users/${itemId}/edit`)} />
          <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
            Extrato de Viajex do usuário: {usuario.user_firstname} {usuario.user_lastname}
          </Text>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <Box
              w="190px"
              h="160px"
              mr="20px"
              boxShadow="xl"
              bg="#FFFFFF"
              borderRadius="15px"
              display="flex"
              flexDir="column"
              alignSelf="center"
              alignContent="center"
              alignItems="center"
              justifySelf="center"
              justifyContent="center"
              justifyItems="center"
            >
              <Text fontSize="42px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
                {cashClubs.saldoN}
              </Text>
              <Text fontSize="14px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
                Saldo de Viajex nacional
              </Text>
            </Box>
            <Box
              w="190px"
              h="160px"
              mr="70px"
              boxShadow="xl"
              bg="#FFFFFF"
              borderRadius="15px"
              display="flex"
              flexDir="column"
              alignSelf="center"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
            >
              <Text fontSize="42px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
                {cashClubs.saldoI}
              </Text>
              <Text fontSize="14px" mt="14px" fontWeight="bold" color="#33303E" textAlign="center">
                Saldo de Viajex internacional
              </Text>
            </Box>
          </Flex>

          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              console.log('Akkkk:: ', e);
              setPageNumber(e.pageIndex + 1);
              setPerPage(e.sPageSize);
            }}
          />
        </Box>
      </Box>
    </Flex>
  );
};
export default Balance;
