import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Box, Button, Flex, Heading, Icon, Input, Select, Text, useToast } from '@chakra-ui/react';
import ResumeCard from '../../components/ResumeCard';
import { BiBuildingHouse, BiFilter } from 'react-icons/bi';
import { VscChecklist, VscEdit } from 'react-icons/vsc';
import { DataGrid } from '../../components/DataGrid';
import { SearchFilter } from '../../components/inputs/filters/search';
import { getCellGeneric, getCellStatus } from '../../utils/FormatFunctions';
import AlertModal from '../../components/AlertModal';
import { InfoButton } from '../../components/Buttons/InfoButton';
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton';
import { ReturnButton } from '../../components/Buttons/ReturnButton';
import { SelectFilter } from '../../components/inputs/filters/select';
import { DateFilter } from '../../components/inputs/filters/date';
import { useFetch } from '../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import IEnterprises from '../../interfaces/IEnterprises';
import { api } from '../../services/api';
import ConfirmModal from '../../components/ConfirmModal';
import ReactTable from '../../components/ReactTable';
import moment from 'moment';

const Exchanges = () => {
  const baseUrl = '/exchanges';
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState<IEnterprises[]>([]);
  const [total, setTotal] = useState(0);
  const [isOpen, onOpen] = useState(true);
  const toast = useToast();

  const [onwership_id, setOnwership_id] = useState('');
  const [enterprise_name, setEnterprise_name] = useState('');
  const [dataV, setDataV] = useState<string>('');
  const [user_firstname, setUser_firstname] = useState('');
  const [uh, setUh] = useState('');
  const [status, setStatus] = useState(''); // Novo estado para o status

  useEffect(() => {
    getData();
  }, [pageNumber, perPage]);

  const getData = async () => {
    try {
      const getData = await api.post(`v1/filter/ownership?pageNumber=${pageNumber}&perPage=${perPage}`, {
        onwership_id: '',
        enterprise_name: enterprise_name,
        enterprise_ids: [],
        user_firstname: user_firstname,
        start_at: dataV.length === 10 ? moment(dataV, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
        status: status, // Incluindo o filtro de status
      });

      if (getData.status === 200) {
        console.log('Aqui:::', getData.data.ownership);
        setData(getData.data.ownership);
        setPageSize(getData.data.paginateCount);
        setTotal(getData.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRegister = async (id: number) => {
    try {
      const deleteData = await api.delete(`v1/ownership/${id}`);
      if (deleteData.status === 200) {
        getData();
        toast({
          title: `Sucesso!`,
          position: 'top',
          status: 'success',
          isClosable: true,
        });
      } else {
        toast({
          title: `Ocorreu um erro ae executar a ação! ${deleteData.data.message}`,
          position: 'top',
          status: 'error',
          isClosable: true,
        });
      }
    } catch (error: any) {
      toast({
        title: `Ocorreu um erro ae executar a ação! ${error!.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
  };

  // const handleFilter = async () => {
  //   try {
  //     setData([]);
  //     const getData = await api.post(`v1/exchanges/filter?pageNumber=${pageNumber}&perPage=${perPage}`, {
  //       id: onwership_id,
  //       hotel: enterprise_name,
  //       start_at: dataV.length === 10 ? moment(dataV, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
  //       uh,
  //       status, // Incluindo o filtro de status
  //     });

  //     if (getData.status === 200) {
  //       console.log('Aqui:::', getData.data.users);
  //       setData(getData.data.users);
  //       setPageSize(getData.data.users);
  //       setTotal(getData.data.users);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // Função para determinar o status com base nos valores de 'user_review_cpt' e 'user_review_enterprise'
  const getStatus = (user_review_cpt: number | null, user_review_enterprise: number | null) => {
    if (user_review_cpt === null && user_review_enterprise === null) {
      return 'available';
    }
    if (user_review_cpt === 0 && user_review_enterprise === 0) {
      return 'both';
    }
    if (user_review_cpt !== 0 && user_review_enterprise === 0) {
      return 'pending_hotel';
    }
    if (user_review_cpt === 0 && user_review_enterprise !== 0) {
      return 'pending_clubshare';
    }
    if (user_review_cpt !== 0 && user_review_enterprise !== 0) {
      return 'approved';
    }
    return '';
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Informações',
        columns: [
          {
            Header: 'Hotel',
            accessor: 'enterprise_name',
            Cell: ({ row }: any) => {
              return <Text>{`${row.original.enterprise.enterprise_name}`}</Text>;
            },
          },
          {
            Header: 'Usuário',
            accessor: 'user_firstname',
            Cell: ({ row }: any) => {
              return <Text>{`${row.original.users.user_firstname} ${row.original.users.user_lastname}`}</Text>;
            },
          },
          {
            Header: 'UH',
            accessor: 'room_type',
            Cell: ({ row }: any) => {
              return (
                <Text>{`${row.original.habitational_unity.room_type} ${row.original.habitational_unity.pax} acomodação(ões)`}</Text>
              );
            },
          },
          {
            Header: 'Iden',
            accessor: 'which_one_hu',
            Cell: ({ row }: any) => {
              return <Text>{`${row.original.which_one_hu}`}</Text>;
            },
          },
          {
            Header: 'Data Inicio',
            Cell: ({ row }: any) => {
              return <Text>{moment(row.original.start_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>;
            },
          },
          {
            Header: 'Data Fim',
            Cell: ({ row }: any) => {
              return <Text>{moment(row.original.end_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>;
            },
          },
          {
            Header: 'Aprovação ClubShare',
            Cell: ({ row }: any) => {
              return <Text>{row.original.user_review_cpt > 0 ? 'Sim' : 'Nao'}</Text>;
            },
          },
          {
            Header: 'Aprovação empresa',
            Cell: ({ row }: any) => {
              return <Text>{row.original.user_review_enterprise > 0 ? 'Sim' : 'Nao'}</Text>;
            },
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }: any) => {
              const item = row.original;

              const getStatusElement = () => {
                if (item.user_review_cpt === 0 && item.user_review_enterprise === 0) {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'red',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Pendente Ambos
                    </span>
                  );
                } else if (
                  item.user_review_cpt > 0 &&
                  (item.user_review_enterprise === null || item.user_review_enterprise === 0)
                ) {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'orange',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Pendente Hotel
                    </span>
                  );
                } else if (
                  (item.user_review_cpt === null || item.user_review_cpt === 0) &&
                  item.user_review_enterprise > 0
                ) {
                  return (
                    <span
                      style={{
                        color: 'black',
                        padding: 4,
                        background: 'yellow',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Pendente Clubshare
                    </span>
                  );
                } else if (item.user_review_cpt === null && item.user_review_enterprise === null) {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'grey',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Disponível
                    </span>
                  );
                } else if (item.user_review_cpt > 0 && item.user_review_enterprise > 0) {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'green',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Depósito Aprovado
                    </span>
                  );
                } else {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'red',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Reprovado
                    </span>
                  );
                }
              };

              return getStatusElement();
            },
          },
          // {
          //   Header: 'Ações',
          //   accessor: 'actions',
          //   Cell: ({ row }: any) => (
          //     <Flex align="center">{ConfirmModal(() => deleteRegister(row.original.onwership_id))}</Flex>
          //   ),
          // },
        ],
      },
    ],
    [],
  );

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
          Intercâmbios
        </Text>
        <Flex flexDir="column" mb="40px">
          <Box display="flex" flexDir="row">
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                ID
              </Text>
              <Input
                type="number"
                placeholder="Ex: 589"
                value={onwership_id}
                onChange={e => setOnwership_id(e.target.value)}
              />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Hotel
              </Text>
              <Input
                placeholder="Ex: Hotel Central"
                value={enterprise_name}
                onChange={e => setEnterprise_name(e.target.value)}
              />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Usuário
              </Text>
              <Input placeholder="Ex: José" value={user_firstname} onChange={e => setUser_firstname(e.target.value)} />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Data
              </Text>
              <Input
                as={InputMask}
                mask={'99/99/9999'}
                placeholder="Ex: 12/12/2020"
                value={dataV}
                onChange={e => setDataV(e.target.value)}
              />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Status
              </Text>
              <Select placeholder="Selecione o status" value={status} onChange={e => setStatus(e.target.value)}>
                <option value="available">Disponível</option>
                <option value="both">Aguardando por ambos</option>
                <option value="pending_hotel">Aguardando Hotel</option>
                <option value="pending_clubshare">Aguardando Club Share</option>
                <option value="approved">Aprovado</option>
              </Select>
            </Box>
          </Box>
          <Flex flexDir="row" mt="20px">
            <Box mt="20px" mr="20px" display="flex" flexDir="row">
              <Box display="flex" alignContent="end" alignItems="end">
                <Button leftIcon={<BiFilter />} onClick={getData} colorScheme="red" variant="solid">
                  Filtrar
                </Button>
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="gamification" />

            <Heading as="h1" size="lg">
              Lista de intercâmbios
            </Heading>
          </Flex>

          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              console.log('Akkkk:: ', e);
              setPageNumber(e.pageIndex + 1);
              setPerPage(e.sPageSize);
            }}
          />
        </Box>
      </Box>
    </Flex>
  );
};
export default Exchanges;
