import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Box, Button, Flex, Heading, Input, Text, useToast } from '@chakra-ui/react';
import { ReturnButton } from '../../components/Buttons/ReturnButton';
import { useNavigate } from 'react-router-dom';
import IEnterprises from '../../interfaces/IEnterprises';
import { api, cangooroo } from '../../services/api';
import ConfirmModal from '../../components/ConfirmModal';
import ReactTable from '../../components/ReactTable';
import moment from 'moment';
import { BiFilter } from 'react-icons/bi';
import { VscEye } from 'react-icons/vsc';
import { IconGeneralButton } from '../../components/Buttons/IconGeneralButton';

const Reservations = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState<IEnterprises[]>([]);
  const [total, setTotal] = useState(0);
  const [isOpen, onOpen] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  const [onwership_id, setOnwership_id] = useState('');
  const [enterprise_name, setEnterprise_name] = useState('');
  const [dataV, setDataV] = useState<string>('');
  const [user_firstname, setUser_firstname] = useState('');
  const [uh, setUh] = useState('');

  useEffect(() => {
    getData();
  }, [pageNumber, perPage]);

  const getData = async () => {
    try {
      const getData = await api.get(`/v1/reservations?pageNumber=${pageNumber}&perPage=${perPage}`);

      if (getData.status === 200) {
        console.log('Aqui:::', getData.data.reservations);
        setData(getData.data.reservations);
        setPageSize(getData.data.paginateCount);
        setTotal(getData.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getData2 = async () => {
    try {
      setData([]);
      const getData = await api.post(`v1/reservations/filter?pageNumber=${pageNumber}&perPage=${perPage}`, {
        user_firstname,
        onwership_id,
        dataV,
      });

      if (getData.status === 200) {
        console.log('Aqui:::', getData.data.reservations);
        setData(getData.data.reservations);
        setPageSize(getData.data.paginateCount);
        setTotal(getData.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Informações',
        columns: [
          {
            Header: 'BookingId',
            accessor: 'BookingId',
            Cell: ({ row }: any) => {
              return <Text>{`${row.original.cng_booking_number} - ${row.original.cng_service_id}`}</Text>;
            },
          },
          {
            Header: 'Usuário',
            accessor: 'MainPax',
            Cell: ({ row }: any) => {
              return <Text>{`${row.original.users.user_firstname} ${row.original.users.user_lastname}`}</Text>;
            },
          },
          {
            Header: 'Data chekin',
            accessor: 'ServiceDate',
            Cell: ({ row }: any) => {
              return <Text>{`${moment(row.original.checkin_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`}</Text>;
            },
          },
          {
            Header: 'Data chekout',
            accessor: 'ServiceDate2',
            Cell: ({ row }: any) => {
              return <Text>{`${moment(row.original.checkout_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`}</Text>;
            },
          },
          {
            Header: 'Status',
            Cell: ({ row }: any) =>
              row.original.status === 1 ? (
                <div
                  style={{
                    backgroundColor: 'green',
                    padding: '2px 5px',
                    borderRadius: '5px',
                    color: 'white',
                    textAlign: 'center',
                    fontSize: '14px',
                  }}
                >
                  Reservado
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: 'red',
                    padding: '2px 5px',
                    borderRadius: '5px',
                    color: 'white',
                    textAlign: 'center',
                    fontSize: '14px',
                  }}
                >
                  Cancelado
                </div>
              ),
          },
          {
            Header: 'Ações',
            accessor: 'actions',
            Cell: ({ row }: any) => (
              <Flex align="center">
                <IconGeneralButton
                  Icon={VscEye}
                  color="#1890FF"
                  fontSize="25px"
                  onClick={() => {
                    navigate(`${row.original.cng_booking_number}/edit`);
                  }}
                />
              </Flex>
            ),
          },
        ],
      },
    ],
    [],
  );

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Text mb="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
          Reservas
        </Text>
        <Flex flexDir="column" mb="40px">
          <Box display="flex" flexDir="row">
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                ID
              </Text>
              <Input
                type="number"
                placeholder="Ex: 589"
                value={onwership_id}
                onChange={e => setOnwership_id(e.target.value)}
              />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Usuário
              </Text>
              <Input placeholder="Ex: José" value={user_firstname} onChange={e => setUser_firstname(e.target.value)} />
            </Box>
            <Box width="16vw" mr="1vw">
              <Text fontWeight="semibold" fontSize="16px" color="#2D3748">
                Data
              </Text>
              <Input
                as={InputMask}
                mask={'99/99/9999'}
                placeholder="Ex: 12/12/2020"
                value={dataV}
                onChange={e => setDataV(e.target.value)}
              />
            </Box>
          </Box>
          <Flex flexDir="row" mt="20px">
            <Box mt="20px" mr="20px" display="flex" flexDir="row">
              <Box display="flex" alignContent="end" alignItems="end">
                <Button leftIcon={<BiFilter />} onClick={getData2} colorScheme="red" variant="solid">
                  Filtrar
                </Button>
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Box>
          <Flex align="center" mb={5}>
            <ReturnButton previousPage="gamification" />

            <Heading as="h1" size="lg">
              Lista de reservas
            </Heading>
          </Flex>

          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={pageSize}
            onChange={(e: any) => {
              setPageNumber(e.pageIndex + 1);
              setPerPage(e.sPageSize);
            }}
          />
        </Box>
      </Box>
    </Flex>
  );
};
export default Reservations;
