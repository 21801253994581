import { Alert, AlertIcon, Box, Button, FormControl, FormLabel, HStack, Input, Spinner, Tag, TagCloseButton, TagLabel, Text, VStack } from "@chakra-ui/react"
import moment from "moment"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { formatToBRLCurrency } from "../../utils/FormatFunctions"
import { api } from "../../services/api"
import logoInfo from '../../assets/logo_info.png'
import { Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react';
import { Quota } from "."

type PropsType = {
    dateUntill: string
    startDate: string
    endDate: string
    valueMonthly: number
    dueDate: string
    quantClients: number
    totalValue: number,
    enterpriseId: number
    allQuotas: Quota[]
    emailList: string[]
    setEmailList: Dispatch<SetStateAction<string[]>>
    dataPayload: Record<string, any> | null
    setDataPayload: Dispatch<SetStateAction<any>>
    isLoadingSendReport: boolean
}

export const ReportCharge = (props: PropsType) => {
    const {
        dateUntill,
        startDate,
        endDate,
        valueMonthly,
        dueDate,
        quantClients,
        totalValue,
        enterpriseId,
        allQuotas,
        emailList,
        setEmailList,
        dataPayload,
        setDataPayload,
        isLoadingSendReport
    } = props

    const [payerName, setPayerName] = useState('')
    const [address, setAddress] = useState('')
    const [zipAndCity, setZipAndCity] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [isLoading, setIsloading] = useState(false)
    const [email, setEmail] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [createAt, setCreatedAt] = useState(new Date());

    const handleInvalidEmail = () => {
        setIsError(true)
        setErrorMessage('Email inválido!')
        setTimeout(() => {
            setIsError(false)
            setErrorMessage('')
        }, 2000)
    }

    const addEmail = () => {
        if (email && validateEmail(email)) {
          setEmailList((prevList) => [...prevList, email]);
          setEmail("");
        }else{
            handleInvalidEmail()
        }
    };
    
    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const removeEmail = (emailToRemove: string) => {
        setEmailList(emailList.filter((email) => email !== emailToRemove));

    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            addEmail();
        }
    };

    const fetchEnterprise = async () => {
        try {
            setIsloading(true)
            const {data} = await api.get(`/v1/enterprises/${enterpriseId}`)
            const enterprise = data.enterprise
            setPayerName(enterprise.enterprise_name)
            setAddress(`
                ${ enterprise.enterprise_address},
                ${ enterprise.enterprise_address_neighborhood},
                ${ enterprise.enterprise_address_number} -
                ${ enterprise.enterprise_address_state} -
                ${ enterprise.enterprise_zipcode} -
                ${ enterprise.enterprise_address_city}/
                ${ enterprise.enterprise_address_state}
            `)
            setZipAndCity(`
                ${ enterprise.enterprise_zipcode} -
                ${ enterprise.enterprise_address_city}/
                ${ enterprise.enterprise_address_state}
            `)
            setCnpj(enterprise.enterprise_cnpj)
            setIsloading(false)

            //SETANDO O PAYLOAD PARA ENVIAR O RELATÓRIO NOS EMAILS
            setDataPayload({
                payer_data: {
                    payer_name: enterprise.enterprise_name,
                    address: `${ enterprise.enterprise_address}, ${ enterprise.enterprise_address_neighborhood}, ${ enterprise.enterprise_address_number} - ${ enterprise.enterprise_address_state} - ${ enterprise.enterprise_zipcode} - ${ enterprise.enterprise_address_city}/${ enterprise.enterprise_address_state}`,
                    zipAnd_city: `${ enterprise.enterprise_zipcode} - ${ enterprise.enterprise_address_city}/${ enterprise.enterprise_address_state}`,
                    cnpj: enterprise.enterprise_cnpj
                },
                report_info_data: {
                    quant_clients: `${moment(startDate).format('DD/MM/YYYY')} à ${moment(endDate).format('DD/MM/YYYY')} : ${quantClients} clientes`,
                    value_monthly: formatToBRLCurrency(valueMonthly),
                    value_total: formatToBRLCurrency(totalValue),
                    due_date: moment(dueDate).format('DD/MM/YYYY'),
                    report_created_at: moment(createAt).format('DD/MM/YYYY HH:mm:ss')
                },
                email_list: emailList,
                clients_list: allQuotas.map(quota => ({
                    name: quota.user_firstname,
                    last_name: quota.user_lastname,
                    cpf: quota.user_cpf,
                    email: quota.email,
                    phone: quota.user_telephone,
                    insert_at: moment(quota.created_at).format('DD/MM/YYYY'),
                    contract_id: quota.contrato_id,
                    status: quota.user_active,
                    last_time_active: moment(quota.last_time_active).format('DD/MM/YYYY')
                }))
            })

        } catch (error) {
            setIsloading(false)
        }
    }

    useEffect(() => {
        fetchEnterprise()
    },[])

    useEffect(() => {
        setDataPayload({...dataPayload, email_list: emailList})
    }, [emailList])

    return(
        <VStack>
            { (isLoading || isLoadingSendReport) ?
                <Spinner size="lg"/>
                :
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <div
                            style={{
                                padding: '20px',
                                width: '25%'
                            }}
                        >
                            <img src={logoInfo} alt="logo" />
                        </div>

                        <div
                            style={{
                                padding: '20px',
                                width: '37%'
                            }}
                        >
                            <Text fontSize="16px" color="#2D3748">
                                <p>Nome do pagador: <strong>{payerName}</strong></p>
                                <p>Endereço: <strong>{address}</strong></p>
                                <p>CEP/Cidade: <strong>{zipAndCity}</strong></p>
                                <p>CNPJ: <strong>{cnpj}</strong></p>
                            </Text>
                        </div>
                    
                        <div
                            style={{
                                padding: '20px',
                                width: '37%'
                            }}
                        >
                            <Text fontSize="16px" color="#2D3748">
                                <p>Quantidade de clientes ativos ao menos uma vez durante período de <strong>{moment(startDate).format('DD/MM/YYYY')}</strong> à <strong>{moment(endDate).format('DD/MM/YYYY')}</strong> : <strong>{quantClients}</strong> clientes.</p>
                                <p>Valor da associação mensal <strong>{formatToBRLCurrency(valueMonthly)}</strong></p>
                                <p>Valor total a pagar <strong>{formatToBRLCurrency(totalValue)}</strong></p>
                                <p>Vencimento: <strong>{moment(dueDate).format('DD/MM/YYYY')}</strong></p>
                                <p>Relatório gerado em <strong>{moment(createAt).format('DD/MM/YYYY HH:mm:ss')}</strong></p>
                            </Text>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', overflowX: 'auto', fontSize: '14px' }}>
                        <TableContainer
                            maxW="1350px"
                            overflowY="auto"
                            border="2px solid teal"
                            borderRadius="md"
                        >
                            <Table variant="simple">
                                <TableCaption>Lista de clientes</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Nome</Th>
                                        <Th>Sobrenome</Th>
                                        <Th>CPF</Th>
                                        <Th>E-mail</Th>
                                        <Th>Telefone</Th>
                                        <Th>Inserido em:</Th>
                                        <Th>ID do Contrato</Th>
                                        <Th>Status</Th>
                                        <Th>Ultima vez Ativo</Th>
                                    </Tr>
                                </Thead>

                                <Tbody>
                                    { allQuotas.map((quota, index) => (
                                        <Tr key={index}>
                                            <Td whiteSpace="normal" overflowWrap="break-word">{quota.user_firstname}</Td>
                                            <Td whiteSpace="normal" overflowWrap="break-word">{quota.user_lastname}</Td>
                                            <Td whiteSpace="normal" overflowWrap="break-word">{quota.user_cpf}</Td>
                                            <Td whiteSpace="normal" overflowWrap="break-word">{quota.email}</Td>
                                            <Td whiteSpace="normal" overflowWrap="break-word">{quota.user_telephone}</Td>
                                            <Td whiteSpace="normal" overflowWrap="break-word">{moment(quota.created_at).format('DD/MM/YYYY')}</Td>
                                            <Td whiteSpace="normal" overflowWrap="break-word">{quota.contrato_id}</Td>
                                            <Td whiteSpace="normal" overflowWrap="break-word">{quota.user_active}</Td>
                                            <Td whiteSpace="normal" overflowWrap="break-word">{moment(quota.last_time_active).format('DD/MM/YYYY')}</Td>
                                        </Tr>
                                    ))}
                                    
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            }


            <VStack spacing={4} align="start" w="100%">
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <HStack>
                    <Input
                        placeholder="Digite o email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <Button onClick={addEmail} colorScheme="blue">
                        Adicionar
                    </Button>
                    </HStack>
                </FormControl>

                {emailList.length > 0 && (
                    <Box>
                    <Text fontWeight="bold" mb={2}>
                        Emails adicionados:
                    </Text>
                    <HStack wrap="wrap" spacing={2}>
                        {emailList.map((emailItem, index) => (
                        <Tag size="lg" key={index} variant="solid" colorScheme="blue">
                            <TagLabel>{emailItem}</TagLabel>
                            <TagCloseButton onClick={() => removeEmail(emailItem)} />
                        </Tag>
                        ))}
                    </HStack>
                    </Box>
                )}
            </VStack>

            {isError && (
                <div style={{ backgroundColor: '#fff' }}>
                    <Alert status="error" mb="20px" borderRadius="5px">
                        <AlertIcon />
                        <p style={{ color: 'white'}}>{errorMessage}</p>
                    </Alert>
                </div>
            )}
        </VStack>
    )
}