import { Box, FormLabel, HStack, Input, VStack } from "@chakra-ui/react"
import { Dispatch, SetStateAction } from "react"

type PropsType = {
    dateUntill: string
    setDateUntill: Dispatch<SetStateAction<string>>
    startDate: string
    setStartDate: Dispatch<SetStateAction<string>>
    endDate: string
    setEndDate: Dispatch<SetStateAction<string>>
    valueMonthly: number
    setValueMonthly: Dispatch<SetStateAction<number>>
    dueDate: string
    setDueDate: Dispatch<SetStateAction<string>>
}

export const FormCharge = (props: PropsType) => {

    const {
        dateUntill,
        setDateUntill,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        valueMonthly,
        setValueMonthly,
        dueDate,
        setDueDate,
    } = props

    return(
        <div 
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px'
            }}
        >
            <HStack spacing={4} mb={'20px'}>
                <Box flex="1">
                    <FormLabel>
                        Inserido Até
                    </FormLabel>
                    <Input type="date" value={dateUntill} onChange={(e) => setDateUntill(e.target.value)}/>
                </Box>

                <Box flex="1">
                </Box>
            </HStack>

            <HStack spacing={4}  mb={'20px'}>
                <Box flex="1">
                    <FormLabel>
                        Inicio Período Ativo
                    </FormLabel>
                    <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                </Box>

                <Box flex="1"  mb={'20px'}>
                    <br/>
                    <FormLabel>
                        Fim Período Ativo
                    </FormLabel>
                    <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                </Box>
            </HStack>

            <HStack spacing={4}>
                <Box flex="1">
                    <FormLabel>
                        Valor Mensal da Associação em Reais (R$)
                    </FormLabel>
                    <Input type="number" value={valueMonthly} onChange={(e) => setValueMonthly(Number(e.target.value))}/>
                </Box>

                <Box flex="1">
                    <br/>
                    <FormLabel>
                        Data de Vencimento
                    </FormLabel>
                    <Input type="date" value={dueDate} onChange={(e) => setDueDate(e.target.value)}/>
                </Box>
            </HStack>
        </div>
    )
}