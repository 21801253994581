import React from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Box,
  Button,
  Input,
  Select,
  Text,
  useDisclosure,
  FormErrorMessage,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BiStore } from 'react-icons/bi';
import IHabitational_Unity from '../../../interfaces/IHabitational_Unity';
import IEnterprises from '../../../interfaces/IEnterprises';
import { useFetch } from '../../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import moment from 'moment';

interface ConfirmModalOwnershipAddProps {
  enterprises: IEnterprises[];
  habitational_unity: IHabitational_Unity[];
  event: () => void;
}

const ConfirmModalOwnershipAdd: React.FC<ConfirmModalOwnershipAddProps> = ({
  enterprises,
  habitational_unity,
  event,
}) => {
  const schema = Yup.object().shape({
    enterprise_id: Yup.string().required('Este campo é obrigatório!'),
    which_one_hu: Yup.string().required('Este campo é obrigatório!'),
    habitational_unity_id: Yup.string().required('Este campo é obrigatório!'),
    contrato_id: Yup.string().nullable().notRequired(),
    user_active: Yup.string().required('Este campo é obrigatório!'),
    start_at: Yup.string()
      .matches(/^\d{2}[./-]\d{2}[./-]\d{4}$/, 'Data inválida')
      .nullable(),
    end_at: Yup.string()
      .matches(/^\d{2}[./-]\d{2}[./-]\d{4}$/, 'Data inválida')
      .nullable(),
    last_time_active: Yup.string()
      .nullable()
      .test('is-date', 'last_time_active must be a valid date', value =>
        value ? moment(value, 'DD/MM/YYYY', true).isValid() : true,
      ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const baseUrl = 'v1/ownership';
  const { requestApi } = useFetch(baseUrl);
  const toast = useToast();
  const { itemId } = useParams(); // ID do usuário logado

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalOpen = () => {
    reset();
    onOpen();
  };

  const handleModalClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (values: any) => {
    const formData = {
      ...values,
      contrato_id: values.contrato_id === '' ? null : values.contrato_id,
      user_id: itemId,
      last_time_active:
        values.user_active === 'ATIVO'
          ? new Date().toISOString()
          : values.last_time_active
          ? moment(values.last_time_active, 'DD/MM/YYYY').toISOString()
          : null,
      start_at: values.start_at ? moment(values.start_at, 'DD/MM/YYYY').toISOString() : null,
      end_at: values.end_at ? moment(values.end_at, 'DD/MM/YYYY').toISOString() : null,
    };

    try {
      const response = await requestApi('post', formData, `${baseUrl}`);

      if (response.status < 299) {
        toast({
          title: 'Sucesso!',
          description: 'Propriedade adicionada com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        handleModalClose();
        event();
      } else {
        // Captura a mensagem de erro corretamente, incluindo a estrutura completa do `response`
        const errorMessage = response?.response?.data?.error || response?.data?.message || 'Erro desconhecido';
        throw new Error(errorMessage);
      }
    } catch (err: any) {
      console.log(err, 'err no catch');
      // Garante que qualquer tipo de erro seja tratado e exibido no toast
      const errorMessage = err?.response?.data?.error || err.message || 'Ocorreu um erro inesperado';
      toast({
        title: 'Erro',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button onClick={handleModalOpen} colorScheme="red" rightIcon={<BiStore />} variant="solid">
        Adicionar
      </Button>
      <Modal size="lg" isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Adicionar Propriedade</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text mb="1rem">Informações</Text>

              {/* Empreendimento */}
              <Box maxW="md" mb="5">
                <FormControl isInvalid={Boolean(errors?.enterprise_id)}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Empreendimento
                  </FormLabel>
                  <Select {...register('enterprise_id')}>
                    {enterprises.map(enterprise => (
                      <option key={enterprise.enterprise_id} value={enterprise.enterprise_id}>
                        {enterprise?.enterprise_name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage color="red">{errors?.enterprise_id?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </Box>

              {/* Número da Cota */}
              <Box maxW="md" mb="5">
                <FormControl isInvalid={Boolean(errors?.which_one_hu)}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Número da Cota
                  </FormLabel>
                  <Input id="which_one_hu" placeholder="Ex: 026/18" {...register('which_one_hu')} />
                  <FormErrorMessage color="red">{errors?.which_one_hu?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </Box>

              {/* Unidade Habitacional */}
              <Box maxW="md" mb="5">
                <FormControl isInvalid={Boolean(errors?.habitational_unity_id)}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Unidade Habitacional
                  </FormLabel>
                  <Select {...register('habitational_unity_id')}>
                    {habitational_unity.map(unity => (
                      <option key={unity?.habitational_unity_id} value={unity.habitational_unity_id}>
                        {unity?.room_type} - {unity?.pax} acomodações
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage color="red">{errors?.habitational_unity_id?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </Box>

              {/* ID do Contrato */}
              <Box maxW="md" mb="5">
                <FormControl isInvalid={Boolean(errors?.contrato_id)}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    ID do Contrato (TSE)
                  </FormLabel>
                  <Input id="contrato_id" placeholder="Ex: 123" {...register('contrato_id')} />
                  <FormErrorMessage color="red">{errors?.contrato_id?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </Box>

              {/* Status do Usuário */}
              <Box maxW="md" mb="5">
                <FormControl isInvalid={Boolean(errors?.user_active)}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Status do Usuário
                  </FormLabel>
                  <Select {...register('user_active')}>
                    <option value="ATIVO">ATIVO</option>
                    <option value="ATRASADO">ATRASADO</option>
                    <option value="CANCELADO">CANCELADO</option>
                  </Select>
                  <FormErrorMessage color="red">{errors?.user_active?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </Box>

              {/* Início */}
              <Box maxW="md" mb="5">
                <FormControl isInvalid={Boolean(errors?.start_at)}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Início
                  </FormLabel>
                  <Input as={InputMask} mask="99/99/9999" placeholder="DD/MM/AAAA" {...register('start_at')} />
                  <FormErrorMessage color="red">{errors?.start_at?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </Box>

              {/* Fim */}
              <Box maxW="md" mb="5">
                <FormControl isInvalid={Boolean(errors?.end_at)}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Fim
                  </FormLabel>
                  <Input as={InputMask} mask="99/99/9999" placeholder="DD/MM/AAAA" {...register('end_at')} />
                  <FormErrorMessage color="red">{errors?.end_at?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </Box>

              {/* Última vez ativo */}
              <Box maxW="md" mb="5">
                <FormControl isInvalid={Boolean(errors?.last_time_active)}>
                  <FormLabel fontWeight="semibold" fontSize="16px" color="#2D3748">
                    Última vez ativo
                  </FormLabel>
                  <Input
                    as={InputMask}
                    mask="99/99/9999"
                    placeholder="Data da última atividade"
                    {...register('last_time_active')}
                  />
                  <FormErrorMessage color="red">{errors?.last_time_active?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={handleModalClose} variant="ghost">
                Sair
              </Button>
              <Button type="submit" isLoading={isSubmitting}>
                Gravar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmModalOwnershipAdd;
