import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Select,
  IconButton,
} from '@chakra-ui/react';
import ReactTable from '../../components/ReactTable';
import { format, isValid } from 'date-fns';
import { ReturnButton } from '../../components/Buttons/ReturnButton';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../services/api';
import { VscEye } from 'react-icons/vsc';
// import moment from 'moment';
import { BiFilter, BiStore } from 'react-icons/bi';
import IUsers from '../../interfaces/IUsers';
import IEnterprises from '../../interfaces/IEnterprises';
import IHabitational_Unity from '../../interfaces/IHabitational_Unity';
import ConfirmModalOwnershipAdd from './modal';

const Ownership = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { itemId } = useParams();

  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState<IUsers[]>([]);
  const [dataEnterprises, setDataEnterprises] = useState<IEnterprises[]>([]);
  const [dataHabitationalUnity, setDataHabitationalUnity] = useState<IHabitational_Unity[]>([]);
  const [usuario, setUsuario] = useState<any>({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedOwnership, setSelectedOwnership] = useState<any>(null);

  // Filtros
  const [filters, setFilters] = useState({
    enterprise_name: '',
    user_firstname: '',
    start_at: '',
    status: '',
  });

  useEffect(() => {
    getUsers();
  }, [pageNumber, perPage, filters]);

  const getUsers = async () => {
    try {
      const getOwnership = await api.get(`v1/ownership/${itemId}?pageNumber=${pageNumber}&perPage=${perPage}`, {
        params: filters,
      });
      const getUser = await api.get(`v1/users/${itemId}`);
      const getEnterprises = await api.get(`v1/enterprises-all`);
      const getHabitationalUnity = await api.get(`v1/habitational_unity`);
      if (getOwnership.status === 200) {
        setData(getOwnership.data.ownership);
        setUsuario(getUser.data);
        setDataEnterprises(getEnterprises.data.enterprises);
        setDataHabitationalUnity(getHabitationalUnity.data.habitational_unity);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRegister = async (id: number) => {
    try {
      const deleteData = await api.delete(`v1/ownership/${id}`);
      if (deleteData.status === 200) {
        getUsers();
        toast({
          title: 'Sucesso!',
          position: 'top',
          status: 'success',
          isClosable: true,
        });
      } else {
        toast({
          title: `Ocorreu um erro: ${deleteData.data.message}`,
          position: 'top',
          status: 'error',
          isClosable: true,
        });
      }
    } catch (error: any) {
      toast({
        title: `Ocorreu um erro: ${error.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
      console.log(error);
    }
  };

  const openDetailsModal = (ownership: any) => {
    setSelectedOwnership(ownership);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Detalhes',
        columns: [
          {
            Header: 'Empreendimento',
            accessor: 'enterprise.enterprise_name',
          },
          {
            Header: 'Qual HU',
            accessor: 'which_one_hu',
          },
          {
            Header: 'Unidade Habitacional',
            accessor: 'habitational_unity.room_type',
            Cell: ({ row }: any) => {
              const roomType = row.original.habitational_unity.room_type;
              const pax = row.original.habitational_unity.pax;
              return (
                <Text>
                  {roomType} - {pax} acomodação(ões)
                </Text>
              );
            },
          },
          {
            Header: 'Inicia',
            accessor: 'start_at',
            Cell: ({ row }: any) => {
              const startDate = new Date(row.original.start_at);
              if (isValid(startDate)) {
                const formattedStartDate = format(startDate, 'dd/MM/yyyy');
                return <Text>{formattedStartDate}</Text>;
              } else {
                return <Text>N/A</Text>;
              }
            },
          },
          {
            Header: 'Fim',
            accessor: 'end_at',
            Cell: ({ row }: any) => {
              const endDate = new Date(row.original.end_at);
              if (isValid(endDate)) {
                const formattedEndDate = format(endDate, 'dd/MM/yyyy');
                return <Text>{formattedEndDate}</Text>;
              } else {
                return <Text>N/A</Text>;
              }
            },
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }: any) => {
              const item = row.original;

              const getStatusElement = () => {
                if (item.user_review_cpt === 0 && item.user_review_enterprise === 0) {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'red',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Pendente Ambos
                    </span>
                  );
                } else if (
                  item.user_review_cpt > 0 &&
                  (item.user_review_enterprise === null || item.user_review_enterprise === 0)
                ) {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'orange',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Pendente Hotel
                    </span>
                  );
                } else if (
                  (item.user_review_cpt === null || item.user_review_cpt === 0) &&
                  item.user_review_enterprise > 0
                ) {
                  return (
                    <span
                      style={{
                        color: 'black',
                        padding: 4,
                        background: 'yellow',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Pendente Clubshare
                    </span>
                  );
                } else if (item.user_review_cpt === null && item.user_review_enterprise === null) {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'grey',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Disponível
                    </span>
                  );
                } else if (item.user_review_cpt > 0 && item.user_review_enterprise > 0) {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'green',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Depósito Aprovado
                    </span>
                  );
                } else {
                  return (
                    <span
                      style={{
                        color: 'white',
                        padding: 4,
                        background: 'red',
                        borderRadius: 5,
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Reprovado
                    </span>
                  );
                }
              };

              return getStatusElement();
            },
          },
          {
            Header: '',
            accessor: 'actions',
            Cell: ({ row }: any) => (
              <Flex align="center">
                <IconButton
                  icon={<VscEye />}
                  aria-label="Ver detalhes"
                  onClick={() => openDetailsModal(row.original)}
                  variant="ghost"
                  colorScheme="blue"
                  size="sm"
                  mr={2}
                />
              </Flex>
            ),
          },
        ],
      },
    ],
    [],
  );

  return (
    <Flex flexDir="column" mt="7vh" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex alignItems="center">
              <ReturnButton onClick={() => navigate(`/ownership/create`)} alignSelf="center" />
              <Text mb="20px" mt="20px" fontSize="24px" fontWeight="bold" color="#2D3748">
                Cotas do usuário: {usuario.user_firstname} {usuario.user_lastname}
              </Text>
            </Flex>
          </Flex>

          <ConfirmModalOwnershipAdd
            enterprises={dataEnterprises}
            habitational_unity={dataHabitationalUnity}
            event={() => console.log('Evento disparado')}
          />
        </Flex>

        <Box>
          {/* Filtros */}
          <Flex mb="20px" flexWrap="wrap" justifyContent="space-between">
            <Input
              placeholder="Empreendimento"
              name="enterprise_name"
              onChange={handleFilterChange}
              value={filters.enterprise_name}
              width="30%"
              mb="10px"
            />
            <Input
              placeholder="Nome do Usuário"
              name="user_firstname"
              onChange={handleFilterChange}
              value={filters.user_firstname}
              width="30%"
              mb="10px"
            />
            <Input
              placeholder="Data de Início"
              name="start_at"
              type="date"
              onChange={handleFilterChange}
              value={filters.start_at}
              width="30%"
              mb="10px"
            />
            <Flex alignItems="center" width="40%" mb="10px">
              <Select
                placeholder="Status"
                name="status"
                onChange={handleFilterChange}
                value={filters.status}
                width="70%"
                mr="10px"
              >
                <option value="both">Pendente Ambos</option>
                <option value="pending_hotel">Pendente Hotel</option>
                <option value="pending_clubshare">Pendente Clubshare</option>
                <option value="available">Disponível</option>
                <option value="approved">Depósito Aprovado</option>
              </Select>
              <Button hidden leftIcon={<BiFilter />} onClick={getUsers} colorScheme="red" variant="solid">
                Filtrar
              </Button>
            </Flex>
          </Flex>
          <ReactTable
            columns={columns}
            pageNumber={pageNumber}
            data={data}
            totaPages={1}
            onChange={(e: any) => {
              setPageNumber(e.pageIndex + 1);
              setPerPage(e.sPageSize);
            }}
          />
        </Box>
      </Box>

      {/* Modal de detalhes */}
      <Modal isOpen={isModalOpen} onClose={closeModal} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes da Cota</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Empreendimento: {selectedOwnership?.enterprise?.enterprise_name}</Text>
            <Text>Unidade Habitacional: {selectedOwnership?.habitational_unity?.room_type}</Text>
            <Text>
              Data de Início:{' '}
              {isValid(new Date(selectedOwnership?.start_at))
                ? format(new Date(selectedOwnership?.start_at), 'dd/MM/yyyy')
                : 'N/A'}
            </Text>
            <Text>
              Data de Fim:{' '}
              {isValid(new Date(selectedOwnership?.end_at))
                ? format(new Date(selectedOwnership?.end_at), 'dd/MM/yyyy')
                : 'N/A'}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={() => deleteRegister(selectedOwnership.onwership_id)}>
              Deletar Cota
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Ownership;
