import { Alert, AlertIcon, Box, Button, ButtonGroup, Center, FormLabel, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import { SetStateAction, useState } from "react"
import { api } from "../../services/api"
import { FormCharge } from "./FormCharge"
import { ReportCharge } from "./ReportCharge"

type PropsType = {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    enterpriseId: number
}

export interface Quota {
    contrato_id: string
    user_active: string
    last_time_active: string
    user_firstname: string
    user_lastname: string
    user_cpf: string
    email: string
    user_telephone: string
    created_at: string
  }

export const ModalReportEnterprise = (props: PropsType) => {
    const {
        isOpen,
        setIsOpen,
        enterpriseId
    } = props

    const [isShowingReport, setIsShowingReport] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isCentered, setIsCentered] = useState(true)
    const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true)
    const [dateUntill, setDateUntill] = useState(new Date().toISOString().split('T')[0])
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0])
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0])
    const [dueDate, setDueDate] = useState(new Date().toISOString().split('T')[0])
    const [valueMonthly, setValueMonthly] = useState(0)
    const [quantClients, setQuantClients] = useState(0)
    const [totalValue, setTotalValue] = useState(0)
    const [allQuotas, setAllQuotas] = useState<Quota[]>([])
    const [emailList, setEmailList] = useState<string[]>([]);

    const [dataPayload, setDataPayload] = useState<Record<string, any> | null>(null)
    const [isLoadingSendReport, setIsLoadingSendReport] = useState(false)
    const [isError, setIsError] = useState(false);
    const [isShowAlert, setIsShowAlert] = useState(false);
    const [message, setMessage] = useState('');

    const handleClose = () => {
        setIsOpen(false)
    }

    const handleSendReport = async () => {
        try {
            setIsLoadingSendReport(true)
            const {data} = await api.post('/v1/enterprises/send_report/emails', dataPayload)
            console.log(data)
            setMessage('Relatório enviado com sucesso')
            setIsShowAlert(true)
            hideAlert()
            setIsLoadingSendReport(false)
            
        } catch (error) {
                console.log(error)
                setIsLoadingSendReport(false)
                setMessage('Erro ao enviar relatório!')
                setIsError(true)
                setIsShowAlert(true)
                hideAlert()
        }
    }

    function hideAlert(){
        setTimeout(() => {
            setMessage('')
            setIsError(false)
            setIsShowAlert(false)
        }, 5000)
    }

    const handleChargeEnterprise = async () => {
        const payload = {
            enterprise_id: enterpriseId,
            created_at_until: dateUntill,
            last_time_active: {
                start: startDate,
                end : endDate
            }
        }

        try {
            setIsLoading(true)
            const {data} = await api.post(`/v1/charge_enterprise`, payload)
            setAllQuotas(data)
            setQuantClients(data.length)
            setTotalValue(data.length * valueMonthly)
            setIsLoading(false)
            setIsShowingReport(true)
            
        } catch (error) {
            console.log(error)
            alert('ERROR TESTE')
            setIsLoading(false)
        }
        
    }
    
    return(
        <Modal
            isCentered
            closeOnEsc={closeOnOverlayClick}
            closeOnOverlayClick={closeOnOverlayClick}
            isOpen={isOpen}
            onClose={handleClose}
        >
            <ModalOverlay />
            <ModalContent maxW={!isShowingReport ? "600px" : "1400px"} w="100%" borderRadius="20px" boxShadow="0px 10px 20px #000000" justifyContent="space-between">
                <ModalHeader
                    borderTopRadius="20px"
                    p={!closeOnOverlayClick ? '20px' : '0px'}
                >
                    <Center>
                        <Text mt="20px" mb="20px" fontSize="20px" fontWeight="bold" color="#2D3748">
                                EMITIR FATURA
                        </Text>
                    </Center>
                </ModalHeader>

                <ModalBody mb={5}>

                    {isShowAlert && (
                        <Center>
                            <div style={{ backgroundColor: '#fff', width: '350px' }}>
                                <Alert status={isError ? "error" : "success"} mb="20px" borderRadius="5px">
                                    <AlertIcon />
                                    <p style={{ color: 'white'}}>{message}</p>
                                </Alert>
                            </div>
                        </Center>
                    )}

                    <Center my={5}>
                        { isLoading ? (
                            <Spinner size={'lg'}/>
                        )
                        :(
                            !isShowingReport ? (
                            <FormCharge
                                dateUntill={dateUntill}
                                setDateUntill={setDateUntill}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                valueMonthly={valueMonthly}
                                setValueMonthly={setValueMonthly}
                                dueDate={dueDate}
                                setDueDate={setDueDate}
                            />)
                            :
                            (
                                <ReportCharge
                                    dateUntill={dateUntill}
                                    startDate={startDate}
                                    endDate={endDate}
                                    valueMonthly={valueMonthly}
                                    dueDate={dueDate}
                                    quantClients={quantClients}
                                    totalValue={totalValue}
                                    enterpriseId={enterpriseId}
                                    allQuotas={allQuotas}
                                    emailList={emailList}
                                    setEmailList={setEmailList}
                                    dataPayload={dataPayload}
                                    setDataPayload={setDataPayload}
                                    isLoadingSendReport={isLoadingSendReport}
                                />
                            )
                        )}
                    </Center>
                </ModalBody>

                <ModalFooter mb={5} justifyContent="center">
                    <Button
                        m={4}
                        isDisabled={isLoading}
                        onClick={handleClose}
                        backgroundColor="red"
                        color="white"
                        colorScheme="red"
                    >
                        Fechar
                    </Button>
                    { !isShowingReport ?
                        <Button
                            isDisabled={isLoading}
                            onClick={handleChargeEnterprise}
                        >
                            Buscar
                        </Button>
                        :
                        <ButtonGroup>
                            <Button
                                m={4}
                                isDisabled={isLoading}
                                onClick={() => setIsShowingReport(false)}
                            >
                                Voltar
                            </Button>
                            
                            <Button
                                colorScheme="blue" 
                                m={4}
                                isDisabled={isLoading || (emailList.length < 1) || isLoadingSendReport}
                                onClick={handleSendReport}
                            >
                                Enviar Fatura Para E-mails
                            </Button>
                        </ButtonGroup>
                    }
                </ModalFooter>
            </ModalContent>
      </Modal>
    )
}